import jwt from "jsonwebtoken";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import loader from "./main-loader.gif";

const Home = React.lazy(() => import("./components/hm"));
const SignUp = React.lazy(() => import("./components/signup"));
const Forgot = React.lazy(() => import("./components/forgot"));
const Resetpassword = React.lazy(() => import("./components/resetpassword"));
const Verifyemail = React.lazy(() => import("./components/verifyemail"));
const Addpro = React.lazy(() => import("./components/Addpro"));
const St1 = React.lazy(() => import("./components/St1"));
const St2 = React.lazy(() => import("./components/St2"));
const St3 = React.lazy(() => import("./components/St3"));
const St4 = React.lazy(() => import("./components/St4"));
const St5 = React.lazy(() => import("./components/St5"));
const St6 = React.lazy(() => import("./components/St6"));
const St7 = React.lazy(() => import("./components/St7"));
const St3multi = React.lazy(() => import("./components/St3multi"));
const Map = React.lazy(() => import("./components/map"));
const Crop = React.lazy(() => import("./components/crop"));
const About = React.lazy(() => import("./components/about"));
const Blog = React.lazy(() => import("./components/blogList"));
const BlogDetail = React.lazy(() => import("./components/blogDetail"));
const Contact = React.lazy(() => import("./components/contact"));
const PrivacyPolicy = React.lazy(() => import("./components/privacyPolicy"));
const Termsofuse = React.lazy(() => import("./components/termcondition"));
const Propertydetail = React.lazy(() => import("./components/property-detail"));
const Propertylist = React.lazy(() => import("./components/pl"));
const PropertylistTest = React.lazy(() => import("./components/ml"));
const MyProfile = React.lazy(() => import("./components/myprofile"));
const ChangePasword = React.lazy(() => import("./components/changepasword"));
const CloseAcount = React.lazy(() => import("./components/closeacount"));
const EnquiryList = React.lazy(() => import("./components/enquirylist"));
const EnquiryListrenter = React.lazy(() =>
  import("./components/enquirylistrenter")
);
const BookingList = React.lazy(() => import("./components/bookinglist"));
const Transaction = React.lazy(() => import("./components/transaction-list"));
const WishList = React.lazy(() => import("./components/wish-list"));
const SavedsearchList = React.lazy(() =>
  import("./components/savedsearch-list")
);
const Success = React.lazy(() => import("./components/success"));
const cancel = React.lazy(() => import("./components/error"));

const AdminLogin = React.lazy(() => import("./components/admin/adminLogin"));
const OTP = React.lazy(() => import("./components/admin/otp"));
const Admin = React.lazy(() => import("./components/admin/admin"));

const error = React.lazy(() => import("./components/notfound"));
const deleteFiles = React.lazy(() => import("./components/delete/App"));
const landlordproperty = React.lazy(() =>
  import("./components/landlordproperties.js")
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      (localStorage.length > 0 && localStorage.getItem("status") == 1) ||
      localStorage.getItem("status") == 2 ? (
        jwt.verify(localStorage.getItem("Uid"), "pokemon", (err, decoded) => {
          if (err) {
            localStorage.clear();
            console.log("props", props);
            props.history.push("/");
          } else {
            return decoded;
          }
        }).email !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="" />
        )
      ) : (
        <Redirect to="/signup" />
      )
    }
  />
);

const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("username") ? (
        <Component {...props} />
      ) : (
        <Redirect to="/adminlogin" />
      )
    }
  />
);

const PrivateRouteRenter = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.length > 0 && localStorage.getItem("status") == 1 ? (
        jwt.verify(localStorage.getItem("Uid"), "pokemon", (err, decoded) => {
          if (err) {
            localStorage.clear();
            console.log("props", props);
            props.history.push("/");
          } else {
            return decoded;
          }
        }).email !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="" />
        )
      ) : (
        <Redirect to="" />
      )
    }
  />
);
class App extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 300);
  }

  render() {
    return (
      <div>
        <Suspense
          fallback={
            <img
              className="main-loader"
              style={{
                position: "absolute",
                top: "30%",
                left: "45%",
                width: "150px",
                height: "150px",
              }}
              src={loader}
              alt=""
            />
          }
        >
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => (
                <React.Suspense fallback={<div></div>}>
                  <Home {...props} />
                </React.Suspense>
              )}
            />
            <Route
              path="/resetpassword"
              component={(props) => (
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Resetpassword {...props} />
                </React.Suspense>
              )}
            />

            <Route
              path="/signup"
              component={(props) => <SignUp {...props} />}
            />
            <Route path="/login" component={(props) => <SignUp {...props} />} />
            <Route
              path="/forgot"
              component={(props) => <Forgot {...props} />}
            />
            <Route
              path="/verifyemail"
              component={(props) => <Verifyemail {...props} />}
            />
            

<Route path="/operation" component={deleteFiles} />
            {/* <PrivateRouteRenter
            path="/addproperty"
            component=
              {(props) => (<Addproperty {...props}/> )}
          /> */}
            {/* <PrivateRoute
            path="/step1"
            component={(props) => (<St1 {...props}/> )}
          />
          <PrivateRoute
            path="/step2"
            component={(props) => (<St2 {...props}/> )}
          />
            <PrivateRoute
            path="/step3"
            component={(props) => (<St3 {...props}/> )}
          />
          <PrivateRoute
            path="/step4"
            component={(props) => (<St4 {...props}/> )}
          />
            <PrivateRoute
            path="/step5"
            component={(props) => (<St5 {...props}/> )}
          />
           <PrivateRoute
            path="/step6"
            component={(props) => (<St6 {...props}/> )}
          />
          <PrivateRoute
            path="/step7"
            component={(props) => (<St7 {...props}/> )}
          /> 
          <PrivateRoute
            path="/St3multi"
            component={(props) => (<St3multi {...props}/> )}
          /> 
          */}
            <PrivateRoute
              path="/addproperty1"
              component={(props) => <Addpro {...props} />}
            />
            {/* <Route
          path="/propertylist"
          component={PropertylistTest}
        /> */}

            {/* <PrivateRouteRenter
            path="/step2"
            component={Addproperty}
          />
          <PrivateRouteRenter
            path="/step3"
            component={Addproperty}
          />
          <PrivateRouteRenter
            path="/step4"
            component={Addproperty}
          />
          <PrivateRouteRenter
            path="/step5"
            component={Addproperty}
          />
          <PrivateRouteRenter
            path="/step6"
            component={Addproperty}
          /><PrivateRouteRenter
            path="/step7"
            component={Addproperty}
          /> */}
            <PrivateRoute path="/step1" component={St1} />
            <PrivateRoute path="/step2" component={St2} />
            <PrivateRoute path="/step3" component={St3} />
            <PrivateRoute path="/step4" component={St4} />
            <PrivateRoute path="/step5" component={St5} />
            <PrivateRoute path="/step6" component={St6} />
            <PrivateRoute path="/step7" component={St7} />
            <PrivateRoute
              path="/addproperty1"
              component={(props) => <Addpro {...props} />}
            />
            <PrivateRoute path="/St3multi" component={St3multi} />

            {/* <Route path="/detailproperty" component={Propertydetail} /> */}
            <Route
              path="/alberta/:city/:property"
              exact
              component={Propertydetail}
            />
            <Route exact path="/alberta/:city" component={Propertylist} />

            <Route path="/alberta" component={Propertylist} />

            <Route
              path="/landlordproperties"
              component={(props) => (
                <React.Suspense fallback={<>...</>}>
                  <landlordproperty {...props} />
                </React.Suspense>
              )}
            />

            <PrivateRoute
              path="/transaction"
              component={(props) => <Transaction {...props} />}
            />
            <PrivateRoute
              path="/propertylist"
              component={(props) => <PropertylistTest {...props} />}
            />
            <Route path="/map" component={(props) => <Map {...props} />} />
            <Route path="/crop" component={(props) => <Crop {...props} />} />
            <Route path="/about" component={(props) => <About {...props} />} />
            <Route
              path="/contact"
              component={(props) => <Contact {...props} />}
            />
            <Route
              path="/privacyPolicy"
              component={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
              path="/termsofuse"
              component={(props) => <Termsofuse {...props} />}
            />

            <PrivateRoute
              path="/myprofile"
              component={(props) => <MyProfile {...props} />}
            />
            <PrivateRoute
              path="/success"
              component={(props) => <Success {...props} />}
            />
            <PrivateRoute
              path="/cancel"
              component={(props) => <cancel {...props} />}
            />
            <PrivateRoute
              path="/changepasword"
              component={(props) => <ChangePasword {...props} />}
            />
            <PrivateRoute
              path="/closeacount"
              component={(props) => <CloseAcount {...props} />}
            />
            <PrivateRoute
              path="/enquirylist"
              component={(props) => <EnquiryList {...props} />}
            />
            <PrivateRoute
              path="/enquirylistrenter"
              component={(props) => <EnquiryListrenter {...props} />}
            />

            <PrivateRoute
              path="/bookinglist"
              component={(props) => <BookingList {...props} />}
            />
            <PrivateRoute
              path="/wishlist"
              component={(props) => <WishList {...props} />}
            />
            <PrivateRoute
              path="/savedsearchList"
              component={(props) => <SavedsearchList {...props} />}
            />

            <Route
              path="/adminlogin"
              component={(props) => <AdminLogin {...props} />}
            />
            <Route path="/otp" component={(props) => <OTP {...props} />} />
            <PrivateRouteAdmin
              path="/admin"
              component={(props) => <Admin {...props} />}
            />
            <Route path="/404" component={error} />
            <Route path="/:category/:blog" exact component={BlogDetail} />
            <Route path="/:category" exact component={Blog} />
            {/* <Route path="/admin" component={Admin} /> */}

            <Route component={error} />
          </Switch>
        </Suspense>
      </div>
    );
  }
}

export default App;
